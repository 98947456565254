import React from 'react';
import parseBEM from '@ctrip/flight-online-style/utils/parseBEM';
import pageShortNameMap from '@ctrip/flight-online-style/constants/pageShortNameMap';
import './army-alert.scss';

const c = parseBEM(pageShortNameMap.list)('army-alert');

const ICON_MAP = new Map([
	['error', 'https://pic.c-ctrip.com/flight_intl/book/svgs/red.svg'],
	['warn', 'https://pic.c-ctrip.com/flight_intl/book/svgs/orange.svg'],
	['success', 'https://pic.c-ctrip.com/flight_intl/book/svgs/green.svg'],
]);

const defaultMessage = `<span>
  部分航司伤残军人(警察)优惠机票正在接入中，如需预订相关航司的伤残军人(警察)优惠机票，请登录航司官网或者致电航司客服预订。
</span>`;

const ArmyAlert = () => {
	const { listArmyAlertMessage } = window.GlobalConfigs || {};
	return (
		<div className={c('$')}>
			<img src={ICON_MAP.get('warn')} alt="" className={c('__icon')} />
			<div
				className={c('__message')}
				dangerouslySetInnerHTML={{ __html: listArmyAlertMessage || defaultMessage }}
			/>
		</div>
	);
};

export default ArmyAlert;
